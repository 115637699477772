


.display-board {
  position: absolute;
  height: 300px;
  width: 500px;
  border: black dashed 2px;
  display: flex;
  flex-direction: column;
  background-color: rgb(555, 200, 789);
  padding: 2%;
}

.chat-history {
  position: absolute;
  height: 300px;
  width: 500px;
  border: black dashed 2px;
  display: flex;
  flex-direction: column;
  color: rgba(242, 243, 244, 0.908);
  font-size: 15px;
  text-align: left;
}

.mrgnbtm {
  margin-top: 20px;
}
